import {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import user from '../store/user'
import UserUpdate from "../UserUpdate";
import Modal from "../UI/Modal";

const Users = observer(() => {
    const [users, setUsers] = useState([])
    const [currentUser, setCurrentUser] = useState(null)
    const [modal, setModal] = useState(false)
    let key1
    if(currentUser) {key1 = currentUser._id}
    useEffect(() => {
        async function getUsers() {
            let res = await fetch('nodeexpress/users', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': `"Bearer ${user.token}`},
            })
            const u = await res.json()
            setUsers (u)
            console.log(u)
        }
        getUsers()
    }, [])

    return (
        <div>
            <Modal
                visible={modal}
                setModal={setModal}
            >
                <UserUpdate
                    item={currentUser}
                    key={key1}
                    setModal={setModal}
                />
            </Modal>
            {users.map(item =>
                <tr onClick={() => {setCurrentUser(item); setModal(true)}}>
                    <td>{item.email}</td>
                    <td>{item.company}</td>
                    <td>{item.discount}</td>
                    <td>{item.unp}{item.address}{item.other}</td>
                </tr>
            )}
        </div>
    );
})

export default Users;