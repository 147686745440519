import React from 'react';
import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import Catalog from "../Catalog";
import CurrentOrder from "../CurrentOrder";
import user from '../store/user'

const Price = observer(() => {
    const [order, setOrder] = useState([])
    const [initialCatalog, setInitialCatalog] = useState([])
    const [catalog, setCatalog] = useState([])
    const [phase, setPhase] = useState(1)
    const [minPrice, setMinPrice] = useState(0)
    let min
    let max

    function search(e) {
        setCatalog(initialCatalog.filter(i => i.name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    function filter1() {
        setCatalog(initialCatalog.filter(i => i.phase === 1 ))
        console.log(phase)
    }
    function filter3() {
        setCatalog(initialCatalog.filter(i => i.phase === 3 ))
        setPhase(phase - phase + 3)
        console.log(phase)
    }
    function filterPrice(e) {
        setCatalog(initialCatalog.filter(i => i.price > e.target.value))
        console.log(minPrice)
    }
    function filtermm() {
        setCatalog(initialCatalog.filter(i => i.price > min && i.price < max ))
        console.log(min)
    }

    function addInOrder(item) {
        if (order.find(i => i._id === item._id)) {
            let itemQuantity = order.find(i => i._id === item._id).quantity
            setOrder(order.map(i => {
                if (i._id === item._id) {
                    return {_id: item._id, name: item.name, quantity: itemQuantity + 1, price: item.price * (100 - user.user.discount ) / 100}
                } else return i
            }))

        } else {
            setOrder([
                ...order,
                {_id: item._id, name: item.name, quantity: 1, price: item.price * (100 - user.user.discount ) / 100}
            ])
        }
    }
    useEffect(() => {
        async function initialCatalogFromMongo() {
            let res = await fetch("nodeexpress/init")
            let catalogFromMongo = await res.json()
            setInitialCatalog(catalogFromMongo)
            setCatalog(catalogFromMongo)
        }
        initialCatalogFromMongo()
    }, [])

    return (
        <>
            <input type="radio" id="1" name="phase" value={phase === 1} onChange={filter1}/>
            <label for="1">1 phase</label>
            <input type="radio" id="3" name="phase" value={phase === 3} onChange={filter3}/>
            <label for="3">3 phase</label>
            <input type="range" id="points" name="points" min="0" max="10000" onChange={e => filterPrice(e)}/>
            <input type="number" onChange={e => min = e.target.value}/>
            <input type="number" onChange={e => max = e.target.value}/>
            <button onClick={filtermm}>price</button>
            <button onClick={() => {console.log(phase); setPhase(1); console.log(phase)}}>3</button>
            <Catalog
                search={search}
                catalog={catalog}
                addInOrder={addInOrder}
            />
            <CurrentOrder
                order={order}
                setOrder={setOrder}
            />
        </>
    );
})

export default Price;