const ArchivalOrders = ({orderList, showArchivalOrder}) => {

    if (!orderList.length) {
        return <h3>Список заказов пуст</h3>
    } else return (
        <div>
            <h2> Список заказов </h2>
            <table>
                <thead>
                <tr>
                    <td>№ заказа</td>
                    <td>Сумма</td>
                    <td>Дата</td>
                </tr>
                </thead>
                <tbody>
                {orderList.map(item =>
                    <tr key={item.date} onClick={() => showArchivalOrder(item)}>
                        <td>{item.numberOrder}</td>
                        <td>{item.itemOrder.reduce((acc, cur) => acc + cur.price * cur.quantity, 0).toFixed(2)}</td>
                        <td>{new Date(item.date).toLocaleString('ru', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                        })}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    );
};

export default ArchivalOrders;