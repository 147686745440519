import { useState } from 'react';
import { Link } from 'react-router-dom'
import styles from './Navbar.module.css'
import user from './store/user'
import {observer} from "mobx-react-lite";

const Navbar = observer (() => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    async function login(email, password) {
        let res = await fetch('nodeexpress/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json;charset=utf-8'},
            body: JSON.stringify({email, password})
        })
        let t = await res.json()
        user.getUserx(t.user)
        user.bearerTokenx(t.token)
    }

    return (
        <div className={styles.back}>
            <ul>
                <input type='text' placeholder='пользователь' onChange={e => setEmail(e.target.value)}/>
                <input type='password' placeholder='Пароль' onChange={e => setPassword(e.target.value)}/>
                <li onClick={() => login(email, password)}>Войти</li>
            </ul>
            <Link to='/'>Каталог</Link>
            <Link to='/orders'>Заказы</Link>
            <Link to='/about'>Реквизиты</Link>
            <Link to='/users'>Пользователи</Link>
        </div>
    )
})

export default Navbar;