import {useRef} from "react";
import html2canvas from "html2canvas";
import {jsPDF} from 'jspdf'
import {observer} from "mobx-react-lite";
import user from './store/user'
import styles from './OneArchivalOrder.module.css'

const OneArchivalOrder = observer(({archivalOrder}) => {
    const printRef = useRef()
    let total
    async function sendMail() {
        let pdfOutput = await pdfToEmail('email')
        let res = await fetch('nodeexpress/mail', {
            method: 'POST',
            headers: {'Content-Type': 'application/json;charset=utf-8'},
            body: JSON.stringify({
                text: `Заказ № ${archivalOrder.numberOrder} на сумму ${total.toFixed(2)} BYN без НДС`,
                attachments: [{ path: pdfOutput}]
            })
        })
    }

    const pdfToEmail = async (param) => {
        const element = printRef.current
        const canvas = await html2canvas(element)
        const data = canvas.toDataURL('image/png')
        const pdf = new jsPDF()
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(data, 'PNG', 20, 20, pdfWidth, pdfHeight);
        if (param === 'email') {
            const pdfOutput = pdf.output("datauristring")
            return pdfOutput
        }
        else {
            pdf.save('Счет № ' + archivalOrder.numberOrder + '.pdf');
        }
    }

    if (archivalOrder === '') {
        return <h3>Не выбран заказ для просмотра</h3>
    } else {
        total = archivalOrder.itemOrder.reduce((acc, cur) => acc + cur.price * cur.quantity, 0)
        return (
            <div>
                <h3>Просмотр архивного заказа</h3>
                    <div ref={printRef}><h4>Заказ № {archivalOrder.numberOrder} от {new Date(archivalOrder.date).toLocaleString('ru', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })} </h4>
                        <p><b>Поставщик: ООО "Скайпрофиль"</b></p>
                        <p>Адрес: Беларусь, г.Минск, Ф.Скорины, д.14, к.317</p>
                        <p>УНП:192431588, р/с AABB CCCC DDDD EEEE EEEE EEEE EEEE</p>
                        <p><b>Покупатель: {user.user.company} </b></p>
                        <p>Адрес:{user.user.address} <br/> УНП: {user.user.unp} <br/> {user.user.bank}<br/> {user.user.other}</p>
                        <table width='80%'>
                            <thead>
                            <tr>
                                <td>№</td>
                                <td>Наименование</td>
                                <td>Кол-во</td>
                                <td>Цена</td>
                                <td>Сумма</td>
                                <td>Сумма НДС</td>
                                <td>Всего с НДС</td>
                            </tr>
                            </thead>
                            <tbody>
                            {archivalOrder.itemOrder.map((item, i) =>
                                <tr key={item.code}>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.price.toFixed(2)}</td>
                                    <td>{(item.quantity * item.price).toFixed(2)}</td>
                                    <td>{(item.quantity * item.price * 0.2).toFixed(2)}</td>
                                    <td>{(item.quantity * item.price * 1.2).toFixed(2)}</td>
                                </tr>)}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan='4'>Итого:</td>
                                <td>{total.toFixed(2)}</td>
                                <td>{(total * 0.2).toFixed(2)}</td>
                                <td>{(total * 1.2).toFixed(2)}</td>
                            </tr>
                            </tfoot>
                        </table>
                        Срок действия счета: 5 рабочих дней.<br/>
                        Отгрузка со склада поставщика в течение 5 рабочих дней с момента 100 % предоплаты на р/с поставщика.<br/><br/>
                        <div className={styles.sign}>
                            <span>Поставщик</span> <span>Покупатель</span>
                            <br/>
                        </div>
                    </div>
                <button onClick={()=> pdfToEmail()}>Скачать счет в PDF</button>
                <button onClick={sendMail}>Выслать на e-mail</button>
            </div>
        );
    }
})
export default OneArchivalOrder;