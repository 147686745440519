import './App.css';
import { Routes, Route } from 'react-router-dom'
import Navbar from "./Navbar";
import About from './pages/About'
import Orders from './pages/Orders'
import Price from "./pages/Price";
import Users from "./pages/Users";

function App() {
    return (
        <>
            <Navbar
            />
            <Routes>
                <Route path='/about' element={<About />}/>
                <Route path='/orders' element={<Orders />}/>
                <Route path='/' element={<Price />}/>
                <Route path='/users' element={<Users />}/>
            </Routes>
        </>
    );
}

export default App;