import {observer} from "mobx-react-lite";
import user from './store/user'

const CurrentOrder = observer(({ order, setOrder}) => {
    const discount = user.user.discount
    const total = order.reduce((acc, cur) => acc + cur.price * cur.quantity, 0)

    function increment(item) {
        console.log(item.price)
        console.log(discount)
        setOrder(order.map(i => {
                if (item._id === i._id) {
                    return {_id: item._id, name: item.name, quantity: item.quantity + 1, price: item.price * (100 - discount ) / 100}
                } else return i
            }
        ))
    }

    function decrement(item) {
        setOrder(order => order.map(i => {
                if (item._id === i._id) {
                    return {_id: item._id, name: item.name, quantity: item.quantity - 1, price: item.price * (100 - discount ) / 100}
                } else return i
            }
        ))
        setOrder(order => order.filter(item => item.quantity > 0))
    }

    async function addInOrderListMongo() {
         await fetch('nodeexpress/addinorderlistmongo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `"Bearer ${user.token}`
            },
            body: JSON.stringify({ author: user.email, date: Date.now(), itemOrder: order})
        })
    }

    if (!order.length) {
        return <h3>Добавьте продукцию в заказ</h3>
    } else return (
        <div>
            <h2>Текущий заказ</h2>
            <table>
                <thead>
                <tr>
                    <td>№</td>
                    <td>Название</td>
                    <td>Кол-во</td>
                    <td>Цена</td>
                    <td>Сумма</td>
                    <td>Сумма НДС</td>
                    <td>Всего с НДС</td>
                </tr>
                </thead>
                <tbody>{order.map((item, i) =>
                    <tr key={item._id}>
                        <td>{i + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.price.toFixed(2)}</td>
                        <td>{(item.quantity * item.price).toFixed(2)}</td>
                        <td>{(item.quantity * item.price * 0.2).toFixed(2)}</td>
                        <td align={"right"}>{(item.quantity * item.price * 1.2).toFixed(2)}</td>
                        <td>
                            <button onClick={() => increment(item)}>+1</button>
                        </td>
                        <td>
                            <button onClick={() => decrement(item)}>-1</button>
                        </td>
                    </tr>)}
                </tbody>
                <tfoot>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Итого:</td>
                    <td>{total.toFixed(2)}</td>
                    <td>{(total * 0.2).toFixed(2)}</td>
                    <td align='right'>{(total * 1.2).toFixed(2)}</td>
                </tr>
                </tfoot>
            </table>
            <button onClick={addInOrderListMongo}>Добавить в список заказов</button>
        </div>
    );
})

export default CurrentOrder;