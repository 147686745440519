import {makeAutoObservable} from 'mobx'

class User {
    user = {discount: 0}
    token = null
    constructor() {
        makeAutoObservable(this)
    }

    getUserx(u) {
        this.user = u
        console.log(this.user.company)
    }
    bearerTokenx(token) {
        this.token = token
        console.log(this.token)
    }

}

export default new User()