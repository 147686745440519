import React, {useEffect} from 'react';
import {useState} from "react";
import {observer} from "mobx-react-lite";
import user from '../store/user'

const About = observer( () => {
    const [user2, setUser2] = useState({discount: 0})
    let company, unp, address, bank, other, token2
    token2 = user.token
    console.log(token2)

    async function getUser() {
        let res = await fetch ('nodeexpress/getuser', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `Bearer ${token2}`}
        })
        let user = await res.json()
        setUser2(user)
        console.log(user)
    }
    async function updateCompany() {
        let res = await fetch('nodeexpress/updatecompany', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': `"Bearer ${token2}`},
            body: JSON.stringify({token2, company, unp, address, bank, other})
        })
        let user2 = await res.json()
        console.log(user2)
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <div>
            Организация: {user2.company}<br/><input type='text' size='50' defaultValue={user2.company}  onChange={e => company = e.target.value}/><br/>
            УНП: {user2.unp}<br/><input type='number' size='9' defaultValue={user2.unp}  onChange={e => unp = e.target.value}/><br/>
            Адрес: {user2.address}<br/><input type='text' size='50' defaultValue={user2.address}  onChange={e => address = e.target.value}/><br/>
            Банк: {user2.bank}<br/><input type='text' size='50' defaultValue={user2.bank}  onChange={e => bank = e.target.value}/><br/>
            Прочее: {user2.other}<br/><textarea maxLength='120' defaultValue={user2.other}  onChange={e => other = e.target.value}/><br/>
            <button onClick={() => {updateCompany(); getUser()}}>Обновить реквизиты</button>
        </div>
    );
})

export default About;