import styles from './Modal.module.css'

const Modal = ({children, visible, setModal}) => {
    let rootClasses = [styles.container]
    if (visible) {rootClasses.push(styles.active)}
    return (
        <div className={rootClasses.join(' ')} onClick={() => setModal(false)}>
            <div className={styles.content} onClick={(event) => event.stopPropagation()}>
                {children}
            </div>

        </div>
    );
};

export default Modal;