import React from 'react';
import {observer} from "mobx-react-lite";
import ArchivalOrders from "../ArchivalOrders";
import OneArchivalOrder from "../OneArchivalOrder";
import {useState, useEffect} from "react";
import user from '../store/user'

const Orders = observer(() => {
    const [orderList, setOrderList] = useState([])
    const [archivalOrder, setArchivalOrder] = useState('')

    function showArchivalOrder(item) {
        setArchivalOrder(orderList.find(i => item.date === i.date))
    }
    useEffect(() => {
        async function getOrder() {
            let res = await fetch("nodeexpress/orders", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': `"Bearer ${user.token}`
                }
            })
            const list = await res.json()
            setOrderList(list)
        }
        getOrder()
    }, [])

    return (
    <>
        <h1>
            Список заказов клиента
        </h1>
        <ArchivalOrders
        orderList={orderList}
        showArchivalOrder={showArchivalOrder}
        archivalOrder={archivalOrder}
    />
        <OneArchivalOrder
            archivalOrder={archivalOrder}
        />
    </>
    );
})

export default Orders;