import styles from './Catalog.module.css'
import user from './store/user'
import {observer} from "mobx-react-lite";

const Catalog = observer( ({search, catalog, addInOrder}) => {

    return (
       <>
        <input
            type='search'
            onChange={e => search(e) }
            placeholder='Введите модель'
        />
        <table className={styles.catalogTable}>
           <thead>
            <tr>
                <td>Название</td>
                <td>Фазы</td>
                <td>МРТ</td>
                <td>Цена</td>
            </tr>
           </thead>
           <tbody>
           {catalog.map(item =>
               <tr key={item._id}>
                   <td style={{textAlign: "left"}}>{item.name}</td>
                   <td>{item.phase}</td>
                   <td>{item.price.toFixed(2)}</td>
                   <td>{(item.price * (100 - user.user.discount ) / 100).toFixed(2)}</td>
                   <td><button onClick={() => addInOrder(item)}>В заказ</button></td>
               </tr>
           )
           }
           </tbody>
        </table>
       </>)
})

export default Catalog