import {observer} from "mobx-react-lite";
import user from './store/user'

const UserUpdate = observer(({item, setModal}) => {

async function updateUser() {
    await fetch('/updateuser', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': `"Bearer ${user.token}`
            },
        body: JSON.stringify({id: item._id,
            email: item.email,
            company: item.company,
            discount: item.discount,
            role: item.role,
            unp: item.unp,
            address: item.address,
            bank: item.bank,
            other: item.other,
        } )
    })
}

    return (
        item !== null ?
            <div>
                E-mail: <br/><input type='text' size='50' defaultValue={item.email}  onChange={e => item.email = e.target.value}/><br/>
                Организация: <br/><input type='text' size='50' defaultValue={item.company}  onChange={e => item.company = e.target.value}/><br/>
                Скидка:
                <select defaultValue={item.discount}  onChange={e => item.discount = e.target.value}>
                    <option value="0">0%</option>
                    <option value="5">5%</option>
                    <option value="10">10%</option>
                    <option value="20">20%</option>
                </select><br/>
                Роль:
                <select defaultValue={item.role} onChange={e => item.role = e.target.value}>
                    <option value="customer">Клиент</option>
                    <option value="admin">Администратор</option>
                </select><br/>
                УНП: <input type='number' defaultValue={item.unp}  onChange={e => item.unp = e.target.value}/><br/>
                Адрес: <br/><input type='text' size='50' defaultValue={item.address}  onChange={e => item.address = e.target.value}/><br/>
                Банк: <br/><input type='text' size='50' defaultValue={item.bank}  onChange={e => item.bank = e.target.value}/><br/>
                Прочее: <br/><textarea maxlength='120' defaultValue={item.other}  onChange={e => item.other = e.target.value}/><br/>
                <button onClick={() => {updateUser(); setModal(false)}}>Обновить реквизиты</button>
            </div>
            : <h3>=</h3>
    )
})

export default UserUpdate;